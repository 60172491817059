<template>
  <div class="Awarp">
    <dashCard
      class="cdzBox5"
      :operateWidth="operateWidth"
      :isLoading="isLoading"
      :gridOption="gridOption"
      @changeScreen="changeScreen"
    >
      <template slot="title">站点排名</template>
      <template slot="aside">
        <div class="type-group">
          <span
            class="pick-type"
            v-for="(item, idx) in timetType"
            :key="idx"
            @click="changeType(item.code)"
          >
            <font :class="{ 'isactive-type': item.code == searchObj.TYPE }">{{
              item.name
            }}</font>
          </span>
        </div>
        <a-date-picker
          v-if="searchObj.TYPE == '按天'"
          :placeholder="commonLangHandler('chuneng7_day','选天', getZEdata)"
          v-model="searchObj.VALUE"
          @change="onChange"
        />
       
        <a-month-picker
          v-if="searchObj.TYPE == '按月'"
          :placeholder="commonLangHandler('chuneng7_month','选月', getZEdata)"
          v-model="searchObj.VALUE"
          @change="onChange"
        />
        <a-date-picker
          v-if="searchObj.TYPE == '按年'"
          :placeholder="commonLangHandler('chuneng7_year','选年', getZEdata)"
          v-model="searchObj.VALUE"
          @change="onChange"
          mode="year"
          :open="panelOpen"
          @openChange="openChange"
          @panelChange="panelChange"
          format="YYYY"
        />
      </template>
      <div
        class="card-content1"
        style="display: flex; justify-content: space-between"
      >
        <a-select
          v-model="searchObj['ITEM']"
          style="width: 220px"
          @change="handleChange"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
        >
          <a-select-option
            v-for="(item, key) in itemList"
            :key="key"
            :value="item.code"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </div>
      <div class="card-content2">
        <chartBoard ref="chart1" :option="chartOption1" />
      </div>
    </dashCard>
    <a-modal
      v-if="!isModal"
      class="Amodal"
      v-model="option.visible"
      :closable="false"
      :footer="null"
      :keyboard="false"
      width="100%"
      :dialog-style="{ top: '0px' }"
    >
      <div style="height: calc(100vh)">
        <cdzBox5 :option="option" :isModal="true" :orginSearchObj="searchObj" />
      </div>
    </a-modal>
  </div>
</template>
    <script>
export default {
  name: "cdzBox5",
  components: {
    dashCard: () => import("@/component/dashCard.vue"),
    chartBoard: () => import("@/component/chartBoard.vue"),
  },
  props: {
    gridOption: {
      type: Object,
      default: function () {
        return { w: 6, h: 6 };
      },
    },
    option: {
      type: Object,
      default: function () {
        return {
          visible: false,
        };
      },
    },
    isModal: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    orginSearchObj: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  watch: {
    gridOption: {
      handler: function (newVal, oldVal) {
        this.$nextTick((_) => {
          this.getOperateWidth();
          var dom = this.$refs["chart1"];
          if (!!dom) {
            dom.handleWindowResize();
          }
        });
      },
      deep: true,
      immediate: true,
    },
    "option.visible": {
      //全屏化时执行
      handler: function (newVal, oldVal) {
        if (newVal) {
          if (this.isModal) {
            this.searchObj = this.orginSearchObj;
            this.getData();
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      operateWidth: 64, //操作列宽度
      isLoading: false, //数据加载中
      timetType: [
        {
          code: "按天",
          name:this.commonLangHandler('chuneng7_day','选天', this.getZEdata)
        },
       
        {
          code: "按月",
          name:this.commonLangHandler('chuneng7_month','选月', this.getZEdata)
        },
        {
          code: "按年",
          name:this.commonLangHandler('chuneng7_year','选年', this.getZEdata)
        },
      ],
      itemList: [
        {
          code: "次数",
          name: "充电次数",
        },
        {
          code: "时长",
          name: "充电时长",
        },
        {
          code: "电量",
          name: "充电电量",
        },
      ],
      panelOpen: false,
      searchObj: {
        TYPE: "按天", //按天,按周,按月,按年
        VALUE: this.$moment(), //日期
        ITEM: "", //选中的项目
      },
      check: "",
      chartOption1: {},
      detailInfo: {
        chart1: {},
      },
      colorList: ["#3366FF", "#74CABB", "#469E3F", "#EDE56F"],
    };
  },
  computed: {
    currentSelectDeptInfo() {
      return this.$store.state.currentSelectDeptInfo;
    },
  },
  methods: {
    // 全屏切换
    changeScreen() {
      this.option.visible = !this.isModal;
      if (!this.option.visible) {
        this.getData();
      }
    },
    // 右侧操作列宽度
    getOperateWidth() {
      if (!this.$el.querySelector) {
        return;
      }
      var dom = this.$el.querySelector(".card-head-operate");
      if (!!dom) {
        var width = dom.clientWidth;
        this.operateWidth = width > 0 ? width + 12 : width;
      }
    },
    // 数字转为千位分隔符表示
    toThousandsSeparator: function (value) {
      if (!value) return 0;
      // 获取整数部分
      const intPart = Math.trunc(value);
      // 整数部分处理，增加,
      const intPartFormat = intPart
        .toString()
        .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
      // 预定义小数部分
      let floatPart = "";
      // 将数值截取为小数部分和整数部分
      const valueArray = value.toString().split(".");
      if (valueArray.length === 2) {
        // 有小数部分
        floatPart = valueArray[1].toString(); // 取得小数部分
        return intPartFormat + "." + floatPart;
      }
      return intPartFormat + floatPart;
    },

    // 改变类型
    changeType(type) {
      this.searchObj.TYPE = type;
      this.getData();
    },
    openChange(status) {
      this.panelOpen = status;
    },
    // 年份弹窗关闭
    panelChange(value) {
      this.searchObj.VALUE = value;
      this.panelOpen = false;
      this.getData();
    },
    onChange(value) {
      console.log("onChange", value, this.searchObj);
      this.getData();
      this.$forceUpdate();
    },
    // 切换项目
    handleChange(value) {
      this.check = value;
      console.log(value);
      this.getData();
    },
    transformSearch: function () {
      var TYPE = this.searchObj.TYPE;
      var dateStr = this.searchObj.VALUE.format("YYYY-MM-DD");
      var VALUE = this.$moment(dateStr);
      var monthVALUE = this.$moment(dateStr).startOf("month");
      var obj = {
        按天: {
          type: "day",
          startDate: VALUE.format("YYYY-MM-DD 00:00:00"),
          endDate: VALUE.format("YYYY-MM-DD 23:59:59"),
        },
        按周: {
          type: "week",
          startDate: VALUE.startOf("week").format("YYYY-MM-DD 00:00:00"),
          endDate: VALUE.endOf("week").format("YYYY-MM-DD 23:59:59"),
        },
        按月: {
          type: "month",
          startDate: monthVALUE.startOf("month").format("YYYY-MM-DD 00:00:00"),
          endDate: monthVALUE.endOf("month").format("YYYY-MM-DD 23:59:59"),
        },
        按年: {
          type: "year",
          startDate: VALUE.startOf("year").format("YYYY-MM-DD 00:00:00"),
          endDate: VALUE.endOf("year").format("YYYY-MM-DD 23:59:59"),
        },
      };
      return obj[TYPE];
    },
    //每月报警预警数
    getData() {
      this.isLoading = true;
      let data = {
        deptCode: this.currentSelectDeptInfo.CODE,
        check: this.check,
        // dateType: "",
        // startDate: "",
        // endDate: "",
        page: "",
        size: "",
      };
      this.detailInfo = {};
      let url = Config.dev_url + "/api-apps/charger/rank";
      Object.assign(data, this.transformSearch());
      data.queryType = data.type;
      delete data.type;
      this.$axios.post(url, data).then((res) => {
        // console.log(res.data.data);
        var chart1 = {
          categoryData: res.data.data.categoryData,
          seriesData: [
            {
              // name: '碳排放',
              name: this.searchObj.TYPE,
              value: res.data.data.seriesData,
            },
          ],
        };
        this.detailInfo.chart1 = chart1;
        this.initEchart();
        this.isLoading = false;
      });
    },
    initEchart() {
      var { categoryData, seriesData } = this.detailInfo.chart1;

      var series = [];
      seriesData.forEach((item) => {
        var obj = {
          type: "bar",
          animationDurationUpdate: 2000,
          animationEasingUpdate: "quinticInOut",
          name: item.name,
          data: item.value,
          smooth: false,
          showSymbol: false,
          barGap: 0,
          barWidth: 10,
          // markLine: {
          //     silent: true,
          //     lineStyle: {
          //         normal: {
          //             color: '#1F1F1F'                   // 这儿设置安全基线颜色
          //         }
          //     },
          //     data: [{
          //         xAxis: 300
          //     }],
          //     label: {
          //         normal: {
          //             color: '#1f1f1f',
          //             fontFamily: 'ABBvoice_WCNSG_Rg',
          //             position: 'end',
          //             formatter: '计划值(300 t)',           // 这儿设置安全基线
          //         }
          //     },
          //     symbol: 'none',//去掉箭头
          // },
        };
        series.push(obj);
      });
      var option = {
        animation: true,
        layoutAnimation: false,
        grid: {
          left: "0%",
          right: "3%",
          bottom: "14%",
          top: "5%",
          containLabel: true,
        },
        dataZoom: [
          {
            type: "inside",
            start: 0,
            end: 100,
            height: 20,
            bottom: 0,
          },
          {
            start: 0,
            end: 100,
            height: 20,
            bottom: 10,
          },
        ],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
        xAxis: {
          type: "value",
          name: "单位(kWh)",
          nameLocation: "start", // 在头部
          axisTick: {
            show: false,
          },
          nameTextStyle: {
            fontFamily: "ABBvoice_WCNSG_Rg",
            color: "#9f9f9f",
            fontSize: 14,

            align: "center",
            verticalAlign: "top",
            padding: [28, 0, 0, 100],
            // padding: [上, 右, 下, 左],
          },
          axisLine: {
            lineStyle: {
              type: "dashed",
              color: "#1f1f1f",
            },
          },
          axisLabel: {
            fontFamily: "ABBvoice_WCNSG_Rg",
            color: "#696969",
            fontSize: 14,
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
              width: 0.5,
              color: "#bababa",
            },
          },
          // 控制数据参数最大值和最小值
          // interval: 50,
          // max: 100
        },
        yAxis: {
          type: "category",
          name: "",
          data: categoryData,
          boundaryGap: true,
          axisLine: {
            lineStyle: {
              color: "#696969",
            },
          },
          axisTick: {
            show: false,
          },
          nameTextStyle: {
            fontFamily: "ABBvoice_WCNSG_Rg",
            color: "#9f9f9f",
            fontSize: 14,
          },
          axisLabel: {
            fontFamily: "ABBvoice_WCNSG_Rg",
            color: "#696969",
            fontSize: 14,
          },
        },
        // 控住柱状图样式
        series: series,
        color: this.colorList,
      };

      this.updateChart("chart1", "chartOption1", option);
    },
    updateChart: function (refName, optionName, option = {}) {
      /* 渲染echart图 */
      if (!optionName) return;
      this[optionName] = option;
      setTimeout(() => {
        this.$refs[refName].updateChartView();
      }, 500);
    },
  },
  mounted() {
    this.getOperateWidth();
    if (!this.option.visible) {
      if (this.itemList.length > 0) {
        this.searchObj.ITEM=this.itemList[0].code
      }
      this.getData();
    }
  },
};
</script>

<style lang="less" scoped>
.cdzBox5 {
  .card-content1 {
    margin-bottom: 20px;
  }
  .card-content2 {
    flex: 1;
    height: 100%;
    display: flex;
    div {
      width: 100%;
      height: 100%;
    }

    .card-content-table {
      width: 296px;
      margin-right: 16px;
      height: inherit;
      .card-content-table-label {
        display: flex;
        justify-content: space-between;
        width: inherit;
        height: 32px;
        border-radius: 4px 4px 0px 0px;
        box-shadow: 0px -2px 0px 0px #0f0f0f inset;
        span {
          font-size: 12px;
          font-weight: 700;
          color: #696969;
          line-height: 32px;
        }
      }
      .card-content-table-item {
        display: flex;
        justify-content: space-between;
        height: 48px;
        width: 100%;
        box-sizing: border-box;
        background: #fafafa;

        font-size: 14px;
        font-weight: 400;
        color: #1f1f1f;
        line-height: 48px;
        span {
          margin-left: 8px;
          margin-right: 8px;
        }
        &:nth-child(2n-1) {
          background: transparent;
        }
      }
    }
    .card-content-box {
      flex: 1;
      height: inherit;
    }
  }
}

.switch {
  width: 165px;
  height: 32px;
  background: #f5f5f5;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  .switch-left {
    width: 58px;
    height: 26px;
    border-radius: 2px;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
    font-size: 14px;
    font-family: ABBvoiceCNSG, ABBvoiceCNSG-Regular;
    font-weight: 400;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.65);
  }
  .switch-left-active {
    width: 58px;
    height: 26px;
    background: #ffffff;
    border-radius: 2px;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
    font-size: 14px;
    font-family: ABBvoiceCNSG, ABBvoiceCNSG-Regular;
    font-weight: 400;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #3366ff;
    cursor: pointer;
  }
  .switch-right {
    width: 100px;
    height: 26px;
    font-size: 14px;
    font-family: ABBvoiceCNSG, ABBvoiceCNSG-Regular;
    font-weight: 400;
    line-height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.65);
  }
  .switch-right-active {
    width: 100px;
    height: 26px;
    font-size: 14px;
    font-family: ABBvoiceCNSG, ABBvoiceCNSG-Regular;
    font-weight: 400;

    line-height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: #3366ff;
    background: #ffffff;
  }
}
</style>
